var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Modal",
    {
      model: {
        value: _vm.showDialog,
        callback: function($$v) {
          _vm.showDialog = $$v
        },
        expression: "showDialog"
      }
    },
    [
      _c(
        "p",
        { attrs: { slot: "header" }, slot: "header" },
        [
          _c("Icon", { attrs: { type: "ios-information-circle" } }),
          _c("span", [_vm._v(_vm._s(_vm.status + _vm.title))])
        ],
        1
      ),
      _c(
        "div",
        [
          _c(
            "Form",
            {
              ref: "formValidate",
              attrs: { model: _vm.menu, rules: _vm.rules, "label-width": 100 }
            },
            [
              _c(
                "FormItem",
                { attrs: { label: "父级菜单", prop: "parentId" } },
                [
                  _c(
                    "Select",
                    {
                      attrs: {
                        disabled:
                          _vm.formStatus === _vm.$options.formStatusEnum.ADD.key
                      },
                      model: {
                        value: _vm.menu.parentId,
                        callback: function($$v) {
                          _vm.$set(_vm.menu, "parentId", $$v)
                        },
                        expression: "menu.parentId"
                      }
                    },
                    [
                      _c("Option", { key: 0, attrs: { value: 0 } }, [
                        _vm._v("一级菜单")
                      ]),
                      _vm._l(_vm.menuList, function(item) {
                        return _c(
                          "Option",
                          { key: item.id, attrs: { value: item.id } },
                          [_vm._v(_vm._s(item.name))]
                        )
                      })
                    ],
                    2
                  )
                ],
                1
              ),
              _c(
                "FormItem",
                { attrs: { label: _vm.title + "名称", prop: "name" } },
                [
                  _c("Input", {
                    attrs: { placeholder: "请输入" + _vm.title + "名称" },
                    model: {
                      value: _vm.menu.name,
                      callback: function($$v) {
                        _vm.$set(_vm.menu, "name", $$v)
                      },
                      expression: "menu.name"
                    }
                  })
                ],
                1
              ),
              _c(
                "FormItem",
                { attrs: { label: _vm.title + "描述", prop: "description" } },
                [
                  _c("Input", {
                    attrs: { placeholder: "请输入" + _vm.title + "描述" },
                    model: {
                      value: _vm.menu.description,
                      callback: function($$v) {
                        _vm.$set(_vm.menu, "description", $$v)
                      },
                      expression: "menu.description"
                    }
                  })
                ],
                1
              ),
              _vm.isMenu
                ? [
                    _c("FormItem", { attrs: { label: "图标" } }, [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "align-items": "center"
                          }
                        },
                        [
                          _c("Icon", {
                            staticStyle: { margin: "0 10px" },
                            attrs: { type: _vm.menu.icon, size: "30" }
                          }),
                          _c(
                            "Button",
                            {
                              attrs: { type: "primary", ghost: "" },
                              on: { click: _vm.handleSelectIcon }
                            },
                            [_vm._v(" 选择图标 ")]
                          )
                        ],
                        1
                      )
                    ]),
                    _c(
                      "FormItem",
                      { attrs: { label: "面包屑隐藏" } },
                      [
                        _c("i-switch", {
                          model: {
                            value: _vm.menu.hideInBread,
                            callback: function($$v) {
                              _vm.$set(_vm.menu, "hideInBread", $$v)
                            },
                            expression: "menu.hideInBread"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "FormItem",
                      { attrs: { label: "排序编号" } },
                      [
                        _c("Input", {
                          attrs: {
                            placeholder: "请输入" + _vm.title + "序列号"
                          },
                          model: {
                            value: _vm.menu.sort,
                            callback: function($$v) {
                              _vm.$set(_vm.menu, "sort", $$v)
                            },
                            expression: "menu.sort"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "FormItem",
                      { attrs: { label: "菜单屑隐藏" } },
                      [
                        _c("i-switch", {
                          model: {
                            value: _vm.menu.hideInMenu,
                            callback: function($$v) {
                              _vm.$set(_vm.menu, "hideInMenu", $$v)
                            },
                            expression: "menu.hideInMenu"
                          }
                        })
                      ],
                      1
                    )
                  ]
                : _vm._e(),
              _c(
                "FormItem",
                { attrs: { label: "仅超级商家可见" } },
                [
                  _c("i-switch", {
                    model: {
                      value: _vm.menu.super,
                      callback: function($$v) {
                        _vm.$set(_vm.menu, "super", $$v)
                      },
                      expression: "menu.super"
                    }
                  })
                ],
                1
              ),
              !_vm.isMenu
                ? _c(
                    "FormItem",
                    { attrs: { label: "权限key", prop: "code" } },
                    [
                      _c("Input", {
                        attrs: { placeholder: "请输入权限key" },
                        model: {
                          value: _vm.menu.code,
                          callback: function($$v) {
                            _vm.$set(_vm.menu, "code", $$v)
                          },
                          expression: "menu.code"
                        }
                      })
                    ],
                    1
                  )
                : _c(
                    "FormItem",
                    { attrs: { label: "路由名称", prop: "code" } },
                    [
                      _c(
                        "Select",
                        {
                          attrs: { filterable: "", "allow-create": "" },
                          on: { "on-create": _vm.handleCreate },
                          model: {
                            value: _vm.menu.code,
                            callback: function($$v) {
                              _vm.$set(_vm.menu, "code", $$v)
                            },
                            expression: "menu.code"
                          }
                        },
                        _vm._l(_vm.routerList, function(item) {
                          return _c(
                            "Option",
                            { key: item.name, attrs: { value: item.name } },
                            [
                              _vm._v(
                                _vm._s(
                                  item.name +
                                    (item.meta
                                      ? "(" + item.meta.title + ")"
                                      : "")
                                )
                              )
                            ]
                          )
                        }),
                        1
                      )
                    ],
                    1
                  ),
              !_vm.isMenu
                ? [
                    _c(
                      "FormItem",
                      { attrs: { label: "接口uri", prop: "description" } },
                      [
                        _c("Input", {
                          attrs: { placeholder: "请输入接口uri" },
                          model: {
                            value: _vm.menu.uri,
                            callback: function($$v) {
                              _vm.$set(_vm.menu, "uri", $$v)
                            },
                            expression: "menu.uri"
                          }
                        })
                      ],
                      1
                    )
                  ]
                : _vm._e()
            ],
            2
          )
        ],
        1
      ),
      _c(
        "div",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _vm.formStatus === _vm.$options.formStatusEnum.ADD.key
            ? _c(
                "Button",
                {
                  attrs: { type: "primary", loading: _vm.loading },
                  on: { click: _vm.handleAddMenuItem }
                },
                [_vm._v("添加" + _vm._s(_vm.title) + " ")]
              )
            : _c(
                "Button",
                {
                  attrs: { type: "primary", loading: _vm.loading },
                  on: { click: _vm.handleUpdateMenuItem }
                },
                [_vm._v("修改" + _vm._s(_vm.title))]
              )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }