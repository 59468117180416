var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("menu-dialog", {
        attrs: {
          "menu-item": _vm.currentMenuItem,
          "form-status": _vm.formStatus,
          "is-menu": _vm.isMenu,
          "menu-type": _vm.menuType,
          "router-list": _vm.routerList,
          "menu-list": _vm.menuList
        },
        on: { "on-success": _vm.handleSuccess },
        model: {
          value: _vm.showDialog,
          callback: function($$v) {
            _vm.showDialog = $$v
          },
          expression: "showDialog"
        }
      }),
      _c("Card", { ref: "header" }, [
        _c(
          "div",
          { staticStyle: { display: "flex" } },
          [
            _c(
              "Button",
              {
                staticStyle: { "margin-left": "auto" },
                attrs: { type: "primary", ghost: "", icon: "md-add" },
                on: { click: _vm.handleAddTopLevelMenuItem }
              },
              [_vm._v(" 添加一级菜单 ")]
            )
          ],
          1
        )
      ]),
      _c("Table", {
        attrs: {
          "row-key": "id",
          "tooltip-theme": _vm.$store.state.theme,
          "load-data": _vm.handleLoadData,
          height: _vm.tableHeight,
          loading: _vm.loading,
          columns: _vm.tableColumns,
          data: _vm.tableData,
          stripe: "",
          border: ""
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }