import Vue from 'vue'
import SelectIcon from '_c/selectIcon/SelectIcon'

function getComponents () {
    const instance = new Vue({
        render (h) {
            return h(SelectIcon)
        }
    })
    const el = instance.$mount().$el
    document.body.appendChild(el)
    return instance.$children[0]
}
export default function () {
    return getComponents()
}
