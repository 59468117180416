<template>
    <Modal v-model="showDialog">
        <p slot="header">
            <Icon type="ios-information-circle"></Icon>
            <span>{{status + title}}</span>
        </p>
        <div>
            <Form ref="formValidate" :model="menu" :rules="rules" :label-width="100">
                <FormItem label="父级菜单" prop="parentId">
                    <Select v-model="menu.parentId" :disabled="formStatus === $options.formStatusEnum.ADD.key">
                        <Option :value="0" :key="0">一级菜单</Option>
                        <Option v-for="item in menuList" :value="item.id" :key="item.id">{{ item.name }}</Option>
                    </Select>
                </FormItem>
                <FormItem :label="`${title}名称`" prop="name">
                    <Input v-model="menu.name" :placeholder="`请输入${title}名称`"></Input>
                </FormItem>
                <FormItem :label="`${title}描述`" prop="description">
                    <Input v-model="menu.description" :placeholder="`请输入${title}描述`"></Input>
                </FormItem>
                <template v-if="isMenu">
                    <FormItem label="图标">
                        <div style="display: flex;align-items: center;">
                            <Icon :type="menu.icon" size="30" style="margin: 0 10px;"></Icon>
                            <Button type="primary"
                                    ghost
                                    @click="handleSelectIcon">
                                选择图标
                            </Button>
                        </div>
                    </FormItem>
                    <FormItem label="面包屑隐藏">
                        <i-switch v-model="menu.hideInBread"/>
                    </FormItem>
                    <FormItem label="排序编号">
                        <Input v-model="menu.sort" :placeholder="`请输入${title}序列号`"></Input>
                    </FormItem>
                    <FormItem label="菜单屑隐藏">
                        <i-switch v-model="menu.hideInMenu"/>
                    </FormItem>

                </template>
                <FormItem label="仅超级商家可见">
                    <i-switch v-model="menu.super"/>
                </FormItem>
                <FormItem v-if="!isMenu" label="权限key" prop="code">
                    <Input v-model="menu.code" :placeholder="`请输入权限key`"></Input>
                </FormItem>

                <FormItem v-else :label="`路由名称`" prop="code">
                    <Select v-model="menu.code" filterable allow-create @on-create="handleCreate">
                        <Option v-for="item in routerList" :value="item.name" :key="item.name">{{ item.name + (item.meta ? '(' + item.meta.title + ')' : '') }}</Option>
                    </Select>
                </FormItem>
                <!--按钮的时候才需要填写-->
                <template v-if="!isMenu">
                    <FormItem label="接口uri" prop="description">
                        <Input v-model="menu.uri" placeholder="请输入接口uri"></Input>
                    </FormItem>
                </template>
            </Form>
        </div>
        <div slot="footer">
            <Button type="primary"
                    :loading="loading"
                    @click="handleAddMenuItem"
                    v-if="formStatus === $options.formStatusEnum.ADD.key">添加{{title}}
            </Button>
            <Button type="primary" @click="handleUpdateMenuItem" :loading="loading" v-else>修改{{title}}</Button>
        </div>
    </Modal>
</template>

<script>
    import dialogMixin from '_m/dialog.mixin'
    import { formStatusEnum, menuTypeEnum } from '_e/index'
    import selectIcon from '_c/selectIcon'

    export default {
        name: 'MenuDialog',
        mixins: [dialogMixin],
        formStatusEnum,
        data () {
            return {
                loading: false,
                menu: {
                    description: '',
                    code: '',
                    isMenu: true,
                    name: '',
                    uri: '',
                    sort: '',
                    parentId: 0,
                    // 在面包屑上隐藏
                    hideInBread: false,
                    // 在菜单栏上隐藏
                    hideInMenu: false,
                    icon: null,
                    type: this.menuType,
                    super: false
                }
            }
        },
        watch: {
            value (value) {
            console.log('value:' + value)
                if (value) {
                    if (this.menuItem) {
                        console.log('menuItem:', this.menuItem)
                        if (this.formStatus === formStatusEnum.ADD.key) {
                            this.menu.parentId = this.menuItem.id
                            this.menu.isMenu = this.isMenu
                            this.menu.super = this.menuItem.type === 2
                        } else {
                            Object.assign(this.menu, this.menuItem)
                            this.menu.super = this.menuItem.type === 2
                        }
                    } else {
                        this.menu.isMenu = true
                    }
                } else {
                    this.handleResetFields()
                }
            }
        },
        computed: {
            title () {
                return this.isMenu ? '菜单' : '按钮'
            },
            status () {
                return formStatusEnum.UPDATE.key === this.formStatus ? '修改' : '添加'
            },
            rules () {
                return {
                    name: [
                        { required: true, message: '菜单名字不能为空', trigger: 'blur' }
                    ],
                    description: [
                        { required: true, message: '菜单描述不能为空', trigger: 'blur' }
                    ],
                    code: [
                        { required: true, message: `${!this.menu ? '权限key' : '路由名称'}不能为空`, trigger: 'blur' }
                    ],
                    uri: [
                        { required: true, message: 'uri不能为空', trigger: 'blur' }
                    ]
                }
            },
        },
        created () {
            this.handleSetProperty()
        },
        props: {
            // 菜单项
            menuItem: {
                type: Object,
                required: false,
                default: () => null
            },
            isMenu: {
                type: Boolean,
                required: true
            },
            menuType: {
                type: Number,
                required: true
            },
            routerList: {
                type: Array,
                default() {
                    return []
                }
            },
            menuList: {
                type: Array,
                default() {
                    return []
                }
            }
        },
        methods: {
            handleSetProperty () {
                Object.assign(this.menu, {
                    id: null,
                    description: '',
                    code: '',
                    isMenu: true,
                    name: '',
                    uri: '',
                    sort: '',
                    parentId: 0,
                    // 在面包屑上隐藏
                    hideInBread: false,
                    // 在菜单栏上隐藏
                    hideInMenu: false,
                    super: false,
                    icon: null,
                    type: this.menuType
                })
            },
            handleCreate(val) {
                this.routerList.push({
                    name: val
                })
            },
            /**
             * 重置表单域
             */
            handleResetFields () {
                this.handleSetProperty()
            },
            handleValidate () {
                return new Promise((resolve, reject) => {
                    this.$refs['formValidate'].validate((valid) => {
                        if (valid) {
                            resolve()
                        } else {
                            this.$Notice.error({
                                title: '表单填写错误',
                                desc: '请正确填写表单'
                            })
                            reject(new Error('表单填写错误'))
                        }
                    })
                })
            },
            /**
             * 添加表单项
             */
            handleAddMenuItem () {
                this.loading = true
                if (this.menuType === 0) {
                    this.menu.type = 0
                } else {
                   if (this.menu.super) {
                       this.menu.type = 2
                   } else {
                      this.menu.type = 1
                   }
                }

                this.handleValidate().then(() => {
                    return this.$http.post('/server/v1/MerchantResource/add', this.menu)
                }).then((response) => {
                    let { code, data } = response
                    if (code === 1) {
                        // 如果当前的为平台的菜单管理
                        if (this.type === menuTypeEnum.PLATFORM.key) {
                            this.$store.dispatch('getMenu')
                        } else {
                            this.$emit('on-success', data)
                            this.showDialog = false
                        }
                    } else {
                       this.$Notice.error({
                         title: `接口报错:code=${code}`,
                         desc: msg
                       })
                    }
                }).finally(() => {
                    this.loading = false
                })
            },
            /**
             * 修改表单项
             */
            handleUpdateMenuItem () {
                this.loading = true
                this.menu.type = this.menuType
                this.handleValidate().then(() => {
                    return this.$http.post(`/server/v1/MerchantResource/update`, this.menu)
                }).then((response) => {
                    const { code } = response
                    if (code === 1) {
                        if (this.type === menuTypeEnum.PLATFORM.key) {
                            this.$store.dispatch('getMenu')
                        } else {
                            this.$emit('on-success')
                            this.showDialog = false
                        }
                    } else {
                        this.$Notice.error({
                          title: `接口报错:code=${code}`,
                          desc: msg
                        })
                    }
                }).finally(() => {
                    this.loading = false
                })
            },
            /**
             * 选择图标
             */
            handleSelectIcon () {
                const instance = selectIcon()
                instance.$on('select-icon', (icon) => {
                    this.menu.icon = icon
                    instance.handleClose()
                })
            }
        }
    }
</script>

<style scoped>
</style>
