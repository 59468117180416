<template>
  <div>
    <menu-dialog v-model="showDialog" :menu-item="currentMenuItem" :form-status="formStatus" :is-menu="isMenu"
      :menu-type="menuType" :router-list="routerList" :menu-list="menuList" @on-success="handleSuccess"></menu-dialog>
    <Card ref="header">
      <div style="display: flex;">
        <Button type="primary" style="margin-left: auto;" ghost icon="md-add" @click="handleAddTopLevelMenuItem">
          添加一级菜单
        </Button>
      </div>
    </Card>
    <!--菜单表格树-->
    <Table row-key="id" :tooltip-theme="$store.state.theme" :load-data="handleLoadData" :height="tableHeight"
      :loading="loading" :columns="tableColumns" :data="tableData" stripe border></Table>
  </div>
</template>

<script>
import MenuDialog from '_platform/view/permission/components/MenuDialog'
import { formStatusEnum } from '_e/index'
import tableHeightMixin from '_m/tableHeight.mixin'

export default {
  name: 'PlatformMenu',
  mixins: [tableHeightMixin],
  components: {
    MenuDialog
  },
  props: {
    menuType: {
      type: Number,
      required: true
    },
    routerList: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      initialTableData: [],
      tableData: [],
      menuList: [],
      loading: false,
      showDialog: false,
      isMenu: false,
      currentMenuItem: null,
      formStatus: formStatusEnum.ADD.key
    }
  },
  created () {
    this.initData()
  },
  computed: {
    tableColumns () {
      let columns = []
      columns.push({
        title: '菜单名称',
        tree: true,
        key: 'name',
        minWidth: 220,
      })
      columns.push({
        title: '菜单描述',
        key: 'description',
        align: 'center',
        minWidth: 200,
        tooltip: true
      })
      columns.push({
        title: '类型',
        align: 'center',
        width: 65,
        tooltip: true,
        render: (h, params) => {
          const { row } = params
          const text = row.isMenu ? '菜单' : '按钮'
          return h('span', text)
        }
      })
      columns.push({
        title: '接口URI',
        align: 'center',
        key: 'uri',
        minWidth: 250,
        tooltip: true
      })
      columns.push({
        title: '图标',
        align: 'center',
        width: 65,
        tooltip: true,
        render: (h, params) => {
          const { row } = params
          return h('Icon', {
            props: {
              type: row.icon,
              size: 20
            }
          })
        }
      })
      columns.push({
        title: '权限key/路由',
        align: 'center',
        key: 'code',
        minWidth: 150,
        tooltip: true
      })
      columns.push({
        title: '操作',
        align: 'center',
        width: 340,
        tooltip: true,
        render: (h, params) => {
          const array = []
          const { row } = params
          //console.log(params)
          if (row.isMenu) {
            //if (!row.children || !row.children.length || row.children[0].isMenu) {
              array.push(h('Button', {
                style: {
                  marginRight: '5px'
                },
                props: {
                  icon: 'md-add',
                  type: 'primary',
                  size: 'small'
                },
                on: {
                  click: () => {
                    this.handleAddChildMenuItem(row)
                  }
                }
              }, '子菜单'))
            //}
            //if (!row.children || !row.children.length || !row.children[0].isMenu) {
              array.push(h('Button', {
                style: {
                  marginRight: '5px'
                },
                props: {
                  icon: 'md-add',
                  type: 'primary',
                  size: 'small'
                },
                on: {
                  click: () => {
                    this.handleAddChildButton(row)
                  }
                }
              }, '按钮'))
            //}
          }
          array.push(h('Button', {
            style: {
              marginRight: '5px'
            },
            props: {
              icon: 'ios-cog',
              type: 'warning',
              size: 'small'
            },
            on: {
              click: () => {
                this.handleUpdateMenuItem(row)
              }
            }
          }, '修改'))
          array.push(h('Button', {
            props: {
              icon: 'ios-trash',
              type: 'error',
              size: 'small'
            },
            on: {
              click: () => {
                this.handleDeleteMenuItem(row)
              }
            }
          }, '删除'))
          return h('div', array)
        }
      })
      return columns
    }
  },
  methods: {
    initData () {
      this.loading = true
      this.$http.get(`/server/v1/MerchantResource/all`, {
        params: {
          refresh: true,
          type: this.menuType
        }
      }).then((response) => {
        let { code, data } = response
        if (code === 1) {
          this.initialTableData = data.resource || []
          this.tableData = this.initialTableData.map(item => {
            let newObj = item
            if (item.children) {
              newObj = {
                ...item,
                children: [],
                _loading: false
              }
            }
            return newObj
          })
          this.findMenuList(this.initialTableData)
        }
      }).finally(() => {
        this.loading = false
      })
    },
    getChildrenData (item, treeData) {
      let newChildren = []
      treeData.forEach(tmp => {
        if (item.id === tmp.id) {
          let children = tmp.children || []
          newChildren = children.map(item => {
            let newItem = item
            if (item.children) {
              newItem = {
                ...newItem,
                children: [],
                _loading: false
              }
            }
            return newItem
          })
        }
      })
      return newChildren
    },
    handleLoadData (item, callback) {
      let children = this.getChildrenData(item, this.menuList)
      callback(children)
    },
    findMenuList (tree) {
      const resList = []
      let cycleList = JSON.parse(JSON.stringify(tree || []))
      while (cycleList.length) {
        let tmp = cycleList.shift()
        if (tmp.isMenu) {
          resList.push(tmp)
          if (tmp.children && tmp.children.length) {
            cycleList = cycleList.concat(tmp.children)
          }
        }
      }

      this.menuList = resList
      console.log(this.menuList)
    },
    handleSuccess () {
      this.initData()
    },
    /**
     * 添加顶层菜单
     */
    handleAddTopLevelMenuItem () {
      this.formStatus = formStatusEnum.ADD.key
      this.currentMenuItem = null
      this.isMenu = true
      this.showDialog = true
    },
    /**
     * 添加子菜单
     * @param parentMenuItem
     */
    handleAddChildMenuItem (parentMenuItem) {
      this.formStatus = formStatusEnum.ADD.key
      this.currentMenuItem = parentMenuItem
      this.showDialog = true
      this.isMenu = true
    },
    handleAddChildButton (parentMenuItem) {
      this.formStatus = formStatusEnum.ADD.key
      this.currentMenuItem = parentMenuItem
      this.showDialog = true
      this.isMenu = false
    },
    /**
     * 修改菜单
     * @param menuItem
     */
    handleUpdateMenuItem (menuItem) {
      this.formStatus = formStatusEnum.UPDATE.key
      this.currentMenuItem = menuItem
      this.showDialog = true
      this.isMenu = menuItem.isMenu
    },
    /**
     * 删除菜单项
     * @param menu
     */
    handleDeleteMenuItem (menu) {
      this.$Modal.confirm({
        title: '删除确认',
        content: `<p>确认删除${menu.isMenu ? '菜单' : '按钮'}-${menu.name}?</p>`,
        onOk: () => {
          this.$http.delete(`/server/v1/MerchantResource/delete/${menu.id}`).then((response) => {
            const { code } = response
            if (code === 1) {
              this.initData()
            }
          })
        }
      })
    }
  },
}
</script>

<style scoped>
</style>
