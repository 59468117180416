<template>
    <div class="select-icon animate__animated animate__bounceInLeft">
        <Icon class="select-icon-close" size="30" type="md-close" @click="handleClose" />
        <div class="select-icon-header">
            <div style="display: flex;align-items: center;justify-content: center;">
                <span class="select-icon-label">过滤筛选：</span>
                <input class="select-icon-input" ref="icon-input"></input>
            </div>
            <span style="margin-top: 10px;font-size: 15px;">点击下面的图标按钮即可</span>
        </div>
        <div class="icon-wrapper">
            <div class="icon-item"
                 :key="index"
                 @click="handleSelectIcon(item)"
                 v-for="(item, index) in iconList">
                <Icon  :type="item" size="30"></Icon>
                <span style="font-size: 14px;margin-top: 5px;">{{item}}</span>
            </div>
        </div>
    </div>
</template>

<script>
    import iconList from '_c/selectIcon/iconList'
    import { debounce } from '@/utils'
    export default {
        name: 'SelectIcon',
        data () {
            return {
                filterCondition: ''
            }
        },
        computed: {
          iconList () {
              const condition = this.filterCondition.trim()
              return iconList.filter((item) => {
                  return item.includes(condition)
              })
          }
        },
        mounted () {
            this.$nextTick().then(() => {
                const handleInput = debounce((event) => {
                    this.filterCondition = event.target.value
                })
                const inputRef = this.$refs['icon-input']
                inputRef.addEventListener('input', handleInput)
                this.$on('hook:beforeDestroy', () => {
                    inputRef.removeEventListener('input', handleInput)
                })
            })
        },
        methods: {
            handleClose() {
                this.$parent.$destroy()
                document.body.removeChild(this.$parent.$el)
            },
            handleSelectIcon (icon) {
                this.$emit('select-icon', icon)
            }
        }
    }
</script>

<style scoped>
    .select-icon-header {
        height: 80px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
    .select-icon-label{
        margin-right: 15px;
        font-size: 15px;
        font-weight: 600;
    }
    .select-icon-input {
        border: 0;
        width: 300px;
        padding: 5px;
        border-bottom: 1px solid  #ccc;
        transition: all .2s;
    }
    .select-icon-input:focus{
        outline-width: 0;
        border-bottom-color: #000c17;
    }
    .icon-wrapper{
        height: calc(100% - 80px);
        width: 100%;
        overflow: auto;
        padding: 30px 20px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
    .select-icon {
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 2000;
        background-color: white;
    }
    .select-icon-close{
        position: absolute;
        z-index: 100;
        right: 10px;
        top: 10px;
        cursor: pointer;
    }
    .icon-item {
        display: flex;
        flex-direction: column;
        margin: 6px 6px 6px 0;
        width: 145px;
        text-align: center;
        list-style: none;
        cursor: pointer;
        height: 100px;
        color: #5c6b77;
        transition: all .2s ease;
    }
    .icon-item:hover{
        transform: translateY(2px);
    }
</style>
